<template>
    <div class="mz-antd-pagination">
       <a-pagination
        v-model="current"
        :page-size-options="pageSizeOptions"
        :total="total"
        show-size-changer
        :page-size="pageSize"
        @showSizeChange="onShowSizeChange"
        >
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
    </div>
  </template>
  
  <script>

  export default {
    props: {
      total:{
        type: Number,
        default: 0
      }, 
      pageSizeOptions: {
        type: Array,
        default() {
          return ['10', '20', '30', '40', '50'];
        }
      },
    },
    data() {
      return {
        pageSize: 10,
        current: 1
      };
    },
    mounted() {
    
    },
    methods: {
      onShowSizeChange(current, pageSize) {
        this.pageSize = pageSize;
        this.current = current;
        this.$emit('onShowSizeChange', current, pageSize);
      }
    },
    watch: {
      current(val) {
        this.$emit('onShowSizeChange', val, this.pageSize);
      },
    },
  };
  </script>
  <style>
  .ant-pagination-prev .ant-pagination-item-link{
    background-color: #EFF2EE;
    color:#262626;

    border: 0;
  }
  .ant-pagination-next .ant-pagination-item-link{
    background-color: #EFF2EE;
    color:#262626;
    border: 0;
  }
  .ant-select-selection{
    background-color: #EFF2EE;
    color:#262626;
    border: 0;
  }
  .ant-pagination-item-link{
    background-color: #EFF2EE;
        border-color: #EFF2EE;
        color:#262626;
        border-radius: 2px;
  }
      .ant-pagination-item {
        background-color: #EFF2EE;
        border-color: #EFF2EE;
        color:#262626;
        border-radius: 2px;
    }
    .ant-pagination-item:hover{
        background-color: #000;
        border-color: #000;
        color: white;
    }
    .ant-pagination-item-active  {
        background-color: #000;
        border-color: #000;
        color: white;
    }

    .ant-pagination-item-active a {
        color: white;
    }
    .ant-pagination-item-active a:hover {
        color: white;
        background-color: #000;
        border-color: #000;
        color: white;
    }
    .ant-pagination-item a:hover{
        color: white;
        background-color: #000;
        border-color: #000;
        color: white;
    }
    .ant-pagination-item-link {
        background-color: #e6f7ff;
    }
  </style>
  <style lang="scss" scoped>
    .mz-antd-pagination{
      .ant-pagination {
        text-align: right !important;
        margin-top: 20px !important;
      }
    }


  </style>
  